import React, { useCallback } from "react";
import { connect } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import background from "../img/new_cover_small.jpg";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import {
  getUserAuthenticated,
} from "selectors/user";
import { openModal } from "actions/modal";
import { SOCIAL_LOGIN_MODAL_ID } from "consts/modalIds";

const Home = ({isAuthenticated, openModal}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const playBtnClicked = useCallback(() => {
    if (!isAuthenticated) {
      openModal(SOCIAL_LOGIN_MODAL_ID);
    } else {
      history.push('/games');
    }
  }, [isAuthenticated]);

  return (
    <>
      <Header></Header>
      <div className="container mx-auto">
          <img src={background} className="mx-auto w-full" />
        <div className="bg-white p-8">
          <div className="mb-8 flex flex-row content-center place-content-center">
            <button
              className="px-6 py-3 text-3xl bg-gold rounded-sm font-bold underline uppercase"
              onClick={playBtnClicked}
            >
              {t("play")}
            </button>
          </div>
          <div className="grid md:grid-cols-6 gap-2">
            <div className="col-span-3">
              <div className="container relative videoContainer">
                <iframe
                  // width="560"
                  // height="315"
                  src="https://www.youtube.com/embed/EHdAu3avhIc"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="absolute top-0 left-0 w-full h-full"
                ></iframe>
              </div>
            </div>
            <div className="col-span-3">
              <div className="font-bold text-lightBlue text-xl mb-10 font-mont">
                {t("homepage_title1")}
              </div>
              <div>{t("homepage_section1")}</div>
              <br />
              <br />
              <div>{t("homepage_section2")}</div>
              <br />
              <div>{t("homepage_section3")}</div>
              <br />
            </div>
          </div>
          <div>{t("homepage_title2")}</div>
          <div className="text-left font-mont">
            <div className="text-lg mt-10 font-bold text-lightBlue">
              {t("homepage_title3")}
            </div>
            <div className="text-blue font-mont mt-7 font-bold">
              <a href="mailto:info@lead.org.mk">info@lead.org.mk</a>
            </div>
            <div>
              <Link to="/privacyPolicy">{t("privacy_policy_title")}</Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: getUserAuthenticated(state),
});

const mapDispatchToProps = {
  openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
// export default Home;
