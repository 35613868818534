import React from "react";
import { Link } from "react-router-dom";
import konradLogo from "img/konrad.svg";
import leadLogo from "img/lead.svg";

const Footer = () => {
  return (
    <div className="container mx-auto flex flex-row place-content-between my-12">
      <a href="https://lead.org.mk/" target="_blank">
        <img src={leadLogo} className="h-20 ml-16" />
      </a>
      <a href="https://www.kas.de/en/web/nordmazedonien" target="_blank">
        <img src={konradLogo} className="h-20 mr-16" />
      </a>
    </div>
  );
};

export default Footer;
